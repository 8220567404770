import { FC, lazy, Suspense } from 'react'
import { Route, RouteProps, Routes as Switch } from 'react-router-dom'

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

type AppRoute = RouteProps & {
  type?: RouteType
}

const Home = lazy(() => import('pages/Home'))

export const AppRoutes: AppRoute[] = [
  {
    type: RouteType.PUBLIC,
    path: '/',
    children: <Home />,
  },
]

const Routes: FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {AppRoutes.map((r) => {
          return (
            <Route key={`${r.path}`} path={`${r.path}`} element={r.children} />
          )
        })}
      </Switch>
    </Suspense>
  )
}

export default Routes
